<template>
  <div style="overflow:hidden;">
    <van-nav-bar
        title="错误"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onBack"
    />
    <div class="van-multi-ellipsis--l3 padding-10" style="text-align: center;margin-top: 40%;">{{ message }}</div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>
<script>
import Loading from "../../components/logding";

export default {
  name: "errot",
  components: { Loading },
  data(){
    return{
      message: this.$route.query.message
    }
  },
  methods: {
    onBack : function (){
      this.$router.go(-2);
    }
  }
}
</script>

<style scoped>

</style>